import { lazy, Suspense } from "react";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";

import useAccess from "./hooks/access";

import Error500 from "./components/Error500";

import Layout from "./components/Layout";
import Spinner from "./components/Spinner";
import NotFound from "./pages/NotFound";

const AdminHome = lazy(() => import("./pages/AdminPages/AdminHome"));
const AdminUsers = lazy(() => import("./pages/AdminPages/Users"));
const AdminCampaigns = lazy(() => import("./pages/AdminPages/Campaigns"));
const Organisations = lazy(() => import("./pages/AdminPages/Organisations"));
const OrganisationCampaigns = lazy(
  () => import("./pages/AdminPages/OrganisationCampaigns")
);
const OrganisationUsers = lazy(
  () => import("./pages/AdminPages/OrganisationUsers")
);
const CreateOrganisation = lazy(
  () => import("./pages/AdminPages/CreateOrganisation")
);
const EditTranscript = lazy(() => import("./pages/AdminPages/EditTranscript"));
const UserInfo = lazy(() => import("./pages/AdminPages/UserInfo"));
const CampaignInfo = lazy(() => import("./pages/AdminPages/CampaignInfo"));
const CampaignValidationStats = lazy(
  () => import("./pages/AdminPages/CampaignValidationStats")
);

const EvaluationItemUpdates = lazy(
  () => import("./pages/AdminPages/EvaluationItemUpdates")
);
const CreateCampaignAdmin = lazy(
  () => import("./pages/AdminPages/CreateCampaign")
);

const CustomAnalyses = lazy(() => import("./pages/CustomAnalyses"));
const AgentCampaign = lazy(() => import("./pages/AgentCampaign"));
const Usage = lazy(() => import("./pages/Usage"));
const UserAccess = lazy(() => import("./pages/UserAccess"));
const Conversation = lazy(() => import("./pages/Conversation"));
const CampaignConversations = lazy(
  () => import("./pages/CampaignConversations")
);
const CampaignSettings = lazy(() => import("./pages/CampaignSettings"));
const Evaluation = lazy(() => import("./pages/EvaluationGridConfig"));
const Organisation = lazy(() => import("./pages/Organisation"));
const UploadCall = lazy(() => import("./pages/UploadCall"));
const UploadConversation = lazy(() => import("./pages/UploadConversation"));
const Profile = lazy(() => import("./pages/Profile"));
const AgentHome = lazy(() => import("./pages/AgentHome"));
const Campaign = lazy(() => import("./pages/Campaign"));
const Campaigns = lazy(() => import("./pages/Campaigns"));
const CreateCampaign = lazy(() => import("./pages/CreateCampaign"));
const Home = lazy(() => import("./pages/Home"));
const Coaching = lazy(() => import("./pages/Coaching"));
const Alerts = lazy(() => import("./pages/Alerts/Alerts"));
const CreateOrUpdateAlert = lazy(
  () => import("./pages/Alerts/CreateOrUpdateAlert")
);

const ConversationPhases = lazy(() => import("./pages/ConversationPhases"));

const AppRoutes: React.FC = () => {
  const { can, role, isAdmin } = useAccess();

  if (isAdmin) {
    const adminRouters = createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" element={<Layout />} errorElement={<Error500 />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<Spinner centered />}>
                <AdminHome />
              </Suspense>
            }
          />
          <Route
            path="/organisations"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Organisations />
              </Suspense>
            }
          />
          <Route
            path="/organisations/create"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CreateOrganisation />
              </Suspense>
            }
          />
          <Route
            path="/organisations/:organisationId/campaigns"
            element={
              <Suspense fallback={<Spinner centered />}>
                <OrganisationCampaigns />
              </Suspense>
            }
          />
          <Route
            path="/organisations/:organisationId/campaigns/create"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CreateCampaignAdmin />
              </Suspense>
            }
          />
          <Route
            path="/organisations/:organisationId/users"
            element={
              <Suspense fallback={<Spinner centered />}>
                <OrganisationUsers />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/users"
            element={
              <Suspense fallback={<Spinner centered />}>
                <AdminUsers />
              </Suspense>
            }
          />
          <Route
            path="/users/:userId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UserInfo />
              </Suspense>
            }
          />
          <Route
            path="/campaigns"
            element={
              <Suspense fallback={<Spinner centered />}>
                <AdminCampaigns />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignInfo />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/evaluation"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Evaluation />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/custom-analyses"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CustomAnalyses />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/validation-stats"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignValidationStats />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/items/:itemId/evaluation-updates"
            element={
              <Suspense fallback={<Spinner centered />}>
                <EvaluationItemUpdates />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/conversations"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignConversations />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/calls/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadCall />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/chats/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadConversation type="chat" />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/emails/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadConversation type="email" />
              </Suspense>
            }
          />
          <Route
            path="/conversations/:conversationId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Conversation />
              </Suspense>
            }
          />
          <Route
            path="conversations/:conversationId/transcript/edit"
            element={
              <Suspense fallback={<Spinner centered />}>
                <EditTranscript />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      )
    );
    return <RouterProvider router={adminRouters} />;
  }

  if (role === "agent") {
    const agentRouters = createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" element={<Layout />} errorElement={<Error500 />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<Spinner centered />}>
                <AgentHome />
              </Suspense>
            }
          />
          <Route
            path="/campaigns"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Campaigns />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <AgentCampaign />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/conversations"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignConversations />
              </Suspense>
            }
          />
          <Route
            path="/conversations/:conversationId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Conversation />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Profile />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      )
    );
    return <RouterProvider router={agentRouters} />;
  }

  if (role === "supervisor") {
    const supervisorRouters = createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" element={<Layout />} errorElement={<Error500 />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Home />
              </Suspense>
            }
          />
          {can("can_view_usage") && (
            <Route
              path="/usage"
              element={
                <Suspense fallback={<Spinner centered />}>
                  <Usage />
                </Suspense>
              }
            />
          )}
          <Route
            path="/organization"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Organisation />
              </Suspense>
            }
          />
          {can("can_edit_users_access") && (
            <Route
              path="organization/users/:userId/access"
              element={
                <Suspense fallback={<Spinner centered />}>
                  <UserAccess />
                </Suspense>
              }
            />
          )}
          <Route
            path="/campaigns"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Campaigns />
              </Suspense>
            }
          />
          {can("can_create_campaign") && (
            <Route
              path="/campaigns/create"
              element={
                <Suspense fallback={<Spinner centered />}>
                  <CreateCampaign />
                </Suspense>
              }
            />
          )}
          <Route
            path="/campaigns/:campaignId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Campaign />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/calls/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadCall />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/chats/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadConversation type="chat" />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/emails/upload"
            element={
              <Suspense fallback={<Spinner centered />}>
                <UploadConversation type="email" />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/evaluation"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Evaluation />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/settings"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignSettings />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/conversation-phases"
            element={
              <Suspense fallback={<Spinner centered />}>
                <ConversationPhases />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/conversations"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CampaignConversations />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/custom-analyses"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CustomAnalyses />
              </Suspense>
            }
          />
          <Route
            path="/conversations/:conversationId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Conversation />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/coaching"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Coaching />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/alerts"
            element={
              <Suspense fallback={<Spinner centered />}>
                <Alerts />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/alerts/create"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CreateOrUpdateAlert />
              </Suspense>
            }
          />
          <Route
            path="/campaigns/:campaignId/alerts/:alertId"
            element={
              <Suspense fallback={<Spinner centered />}>
                <CreateOrUpdateAlert />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      )
    );
    return <RouterProvider router={supervisorRouters} />;
  }

  return (
    <BrowserRouter>
      <Error500 />
    </BrowserRouter>
  );
};

export default AppRoutes;
